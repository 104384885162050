import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";
import "common/assets/css/main-page.css";

import Seo from "components/seo";

import TitleBar2 from "common/components/Titlebar2";
import SingleServicePage from "../containers/Home/pages.style";
import ServiceSectionTwo from "../containers/Sections/ServiceSectionTwo";
import ServiceSectionThree from "../containers/Sections/ServiceSectionThree";
import ServiceSectionOne from "../containers/Sections/ServiceSectionOne";
import ServiceSectionFour from "../containers/Sections/ServiceSectionFour";

const WigsForMenCancerPatient = () => {
  const seoData = {
    title: 'Wigs For Cancer Patients in Delhi - Radiance Hair Studio',
    description: 'Baldness due to chemotherapy? Radiance Hair Solution offers the most trusted wigs for cancer patients in Delhi. Visit us today',
    keywords: ['wigs for cancer patients in delhi'],
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "@id": "Service",
    "alternateName": "Radiance Hair Studio",
    "areaServed": "New Delhi",
    "image": [
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/wigs-for-men-cancer-patients.png",
      "https://www.radiancehairstudio.com/wigs-for-men-cancer-patients1.png",
      "https://www.radiancehairstudio.com/wigs-for-men-cancer-patients2.png",
      "https://www.radiancehairstudio.com/help-image-1.png",
      "https://www.radiancehairstudio.com/home/home-image-18.webp"
    ],
    "logo": "https://www.radiancehairstudio.com/static/radiance-logo-721b0cd4def3d400c04ba899afd38810.png",
    "additionalType": "https://www.radiancehairstudio.com/wigs-for-men-cancer-patients.php",
    "category": "https://www.radiancehairstudio.com/wigs-for-men-cancer-patients.php",
    "description": "Baldness due to chemotherapy? Radiance Hair Solution offers the most trusted wigs for cancer patients in Delhi. Visit us today.",
    "disambiguatingDescription": "Wigs for cancer patients in Delhi",
    "mainEntityOfPage": "Wigs For Cancer Patients",
    "serviceType": "Wigs For Cancer Patients in Delhi"
  };   

  const sectionS1 = [
    {
      title: "Hair Patch for Men",
      img: "/hair-patch-for-men.png",
    },
    {
      title: "Human Hair Extension",
      img: "/7.png",
    },
    {
      title: "Hair Weaving",
      img: "/8.png",
    },
    {
      title: "Non-Surgical Hair Replacement",
      img: "/9.png  ",
    }
  ];

  const sectionS2 = [
    {
      img: "/wigs-for-men-cancer-patients.png",
      content: (
        <div>
          <h2>Men's Wigs for Cancer Patients</h2>
          <p>
          Cancer patients are treated with Chemotherapies. Hair loss is a common side effect of chemotherapy, whether delivered through radiation or oral medications. To address the baldness caused by this treatment, opting for high-quality hair wigs in Delhi can be an excellent solution. 
          </p>
          <h2>Regards from Radiance</h2>
          <p>
          At Radiance  Hair Studio, we treat your wig selection as a fashion statement while ensuring it meets all your personal needs. This attention to detail is why we are a preferred choice for wigs designed specifically for cancer patients.
          </p>
          <p>
          The process begins with trimming your hair to the shortest length, followed by a consultation with an expert hairdresser for proper guidance. 
          </p>
          <p>
          Our professionals carefully assess factors such as skin tone, the <a href="/hair-extension-for-womens.php" style={{ color: "#373737"}}> extent of hair loss</a>, and other individual requirements to help you choose the most suitable wig. At Radiance, we pride ourselves on delivering exceptional service, including detailed instructions on how to
          use and care for your wig. From determining the perfect size to selecting the right material, we ensure the entire process is seamless and stress-free. Let us help you regain your confidence with our expertise and dedication to excellence. Keeping the seriousness of cancer in our mind we use completely harmless products to attach wigs on the skull of the patient.We understand that synthetic hair may not be suitable for everyone, which is why we exclusively use 100% natural human hair to craft our wigs.  Each individual is given personalized attention to ensure the best experience. 

          </p>
          <p>
          To help you better understand the benefits and address common questions, we provide guidance on everything you need to know. 
          </p>
        </div>
      ),
    },
  ];
  
  const sectionS3 = [
    {
      smallImg: "/wigs-for-men-cancer-patients1.png",
      largeImg: "/wigs-for-men-cancer-patients2.png",
      content: (
        <div>
          <h2>Benefits of Using Hair Wigs in Delhi</h2>
          <p></p>
          <ul>
            <li>
            Hair loss is a common side effect of cancer treatments, and for many patients, it can be emotionally challenging. Wearing a wig can help restore confidence by providing a natural look and a sense of normalcy during this difficult time.
            </li>
            <li>
            Wigs designed for cancer patients are lightweight and easy to wear, fitting securely like a cap. Since they are not permanently attached, they can be easily removed before chemotherapy sessions and worn again without any hassle.
            </li>
            <li>
            Affordability is another advantage. Compared to other hair restoration treatments, <a href="/wigs-for-men.php" style={{ color: "#373737"}}> wigs</a> are a cost-effective option. Among the various types of wigs available, those designed for medical purposes tend to be the most budget-friendly.
            </li>
            <li>
            Additionally, hair wigs are completely external and do not cause any side effects. However, it is essential to remove the wig before undergoing chemotherapy to ensure a smooth treatment process.

            </li>
            <li>
            Choosing hair wigs in Delhi offers convenience, affordability, and a confidence boost for individuals dealing with hair loss.
            </li>
          </ul>
        </div>
      ),
    },
  ];
  
  const sectionS4 = [
    {
      title: "FAQs About Wigs for Cancer Patients",
      description: "",
      description2: "At Radiance, we are dedicated to helping you regain your confidence with high-quality, comfortable wigs. Visit us for a consultation today!",
      faq: [
        {
          title: "How easy is it to wear a wig?",
          description:
            "At Radiance, our wigs are designed for comfort and ease of use—just like wearing a cap. You can easily put on and remove the wig yourself. During your first visit, our professionals will guide you step by step on how to wear and style it, ensuring you feel confident doing it on your own.",
        },
        {
          title: "What are the available lengths and materials?",
          description:
            "We offer wigs in various lengths, customized to your preference. Our wigs are made from 100% human hair, as it provides a natural feel and appearance. Since chemotherapy and radiation treatments can make the scalp sensitive, we avoid synthetic materials to ensure maximum comfort and safety.",
        },
        {
          title: "How much do cancer wigs cost?",
          description:
            "Wigs are one of the most affordable hair solutions, especially compared to other external hair replacement methods. At Radiance, we ensure that our wigs are budget-friendly while maintaining top-notch quality.",
        },
        {
          title: "Will the wig look natural?",
          description:
            "Yes! We carefully match the wig’s color and texture to complement your skin tone and complexion. Since chemotherapy can cause skin darkening, our experts take special care in selecting the perfect shade to ensure a natural and seamless look.",
        }
      ],
    },
  ];
  

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
      <Seo {...seoData} schemaData={schemaData} />
        <Layout>
          <TitleBar2 title="Wigs For Cancer Patients in Delhi" banner="/wigs-for-cancer-patients-in-delhi-banner.png"/>
          <SingleServicePage>
            <ServiceSectionOne data={sectionS1} title="Wigs For Cancer Patients" />
            <ServiceSectionTwo data={sectionS2} />
            <ServiceSectionThree data={sectionS3} />
            <ServiceSectionFour data={sectionS4} />
          </SingleServicePage>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default WigsForMenCancerPatient;
